@use "../global/_global-dir" as *;
@use "../bootstrap.scss" as bootstrap;

/*App Layout Styles*/
#app-site,
.app-main {
  display: flex;
  width: 100%;
  height: 100vh;

  .app-container {
    @include display-flex(flex, row, nowrap);
    width: 100%;

    .app-main-container {
      @include display-flex(flex, column, nowrap);
      width: 100%;
      overflow: hidden;

      .app-main-content-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        .app-main-content {
          @include display-flex(flex, column, nowrap);
          flex: 1 1 100%;
          width: 100%;
          min-height: calc(100% - 2.8rem); // subtracted footer height

          & > div:not([class]) {
            // It's here to allow the SPV loading to be centered
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            flex: 1 1 100%;
          }

          @include bootstrap.media-breakpoint-down(lg) {
            min-height: calc(100% - 2rem); // subtracted footer height
          }
        }
        .app-main-content-v2 {
          @include display-flex(flex, column, nowrap);
          flex: 1 1 100%;
          width: 100%;
          min-height: calc(100% - 2.8rem); // subtracted footer height
          background-color: white;

          & > div:not([class]) {
            // It's here to allow the SPV loading to be centered
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            flex: 1 1 100%;
          }

          @include bootstrap.media-breakpoint-down(lg) {
            min-height: calc(100% - 2rem); // subtracted footer height
          }
        }
      }

      // we have this here so that it can be applied to the header as well
      .content-wrapper {
        flex: 1 1 0;
        width: 100%;
        padding: 15px 15px 0 15px;
        margin-right: auto;
        margin-left: auto;
        //max-width: 1240px;  // if adding the breakpoints, remove this line

        /* @include bootstrap.media-breakpoint-up(sm) {
          max-width: 540px;
        }*/

        @include bootstrap.media-breakpoint-up(md) {
          max-width: 720px;
        }

        @include bootstrap.media-breakpoint-up(lg) {
          max-width: 960px;
        }

        @include bootstrap.media-breakpoint-up(xl) {
          max-width: 1240px;
        }
      }

      .content-wrapper-fluid {
        flex: 1 1 0;
        width: 100%;
        padding: 15px 15px 0 15px;
        margin-right: auto;
        margin-left: auto;
      }

      .content-container {
        margin: 1.5rem;

        @include bootstrap.media-breakpoint-up(md) {
          margin: 1.5rem 5.188rem;
        }
      }
    }
  }
}
