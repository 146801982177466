@use "../global/_global-dir" as *;

$collumn-divisor: 1.5rem;

.reformulate-pd-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .reformulate-buttons {
        display: flex;
        align-items: center;
        margin-bottom: 0.75rem;

        width: 100%;
        // overflow-x: scroll;

        button {
            margin-right: 1rem;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.pd-table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    position: relative;
    overflow-y: scroll;

    thead {
        position: sticky;
        top: 0;
        background-color: var(--paper-color);
    }

    .th {
        min-width: 140px;
        height: 35px;
        font-weight: $font-weight-normal !important;
        font-size: 0.875rem;
        border-radius: 5px;

        &-pos {
            @extend .th;
            color: var(--primary-color);
            background-color: var(--primary-color-bleached);
        }

        &-pre {
            @extend .th;
            color: rgba(32, 32, 32, 0.5);
            background-color: var(--primary-color-bleached);
        }

        // OLD AND REFORMULATE -> For Reformulate Dialog Table
        &-old {
            @extend .th;
            color: #ffffff;
            max-width: 238px;
            background-color: #5d646b;
            padding: 0.5rem;
            height: unset;
            min-height: 35px;
        }

        &-reformulate {
            @extend .th;
            color: #ffffff;
            max-width: 238px;
            background-color: var(--primary-color);
            padding: 0.5rem;
            height: unset;
            min-height: 35px;
        }

        &-divisor {
            width: $collumn-divisor !important;
        }
    }

    .td {
        &-label {
            @extend .td;
            width: 158px;
            color: #5a5759;
            font-weight: $font-weight-semibold;
            font-size: 0.75rem;
            height: 28px;
            background-color: $steel-100;
            border-radius: 5px;
            padding: 0.375rem 0.5rem;
            &.tooltip-efz {
                display: flex;
                height: 100%;
                align-items: center;
            }
        }

        &-pos {
            font-size: 0.875rem;
            line-height: 19px;
            border-bottom: 1px solid $neutral-200;
            color: rgba(32, 32, 32, 0.6);

            &-bold {
                @extend .td-pos;
                font-weight: $font-weight-semibold;
                color: #202020;
            }
        }

        &-pre {
            font-size: 0.875rem;
            line-height: 19px;
            color: rgba(32, 32, 32, 0.6);
            border-bottom: 1px solid $neutral-200;
        }

        &-divisor {
            width: $collumn-divisor !important;

            &-underline {
                @extend .td-divisor;
                border-bottom: 1px solid $neutral-200;
            }
        }
    }

    .tr {
        &-divisor {
            height: 0.5rem;
        }
        &-section-divisor {
            height: 1.5rem;
        }
    }

    tr {
        h1 {
            font-family: Poppins;
            font-size: 14px;
            line-height: 18px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            font-weight: 500;
            margin: 0 0 8px 0;
            color: var(--primary-color);
            width: max-content;
        }
    }


    .MuiOutlinedInput-root {
        width: 80%;
        min-height: 27px !important;
        height: 27px !important;
        margin-bottom: 0.3rem;

        .MuiInputAdornment-root {
            color: #a3a3a3;
        }

        // Position of unit
        .MuiInputAdornment-positionStart {
            margin-right: -4px;
        }

        input {
            padding-right: 0 !important;
        }
    }

    .half-outlined {
        width: 35% !important;
    }
}
